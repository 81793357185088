import './info-badge.scss'
import SVGInline from 'react-svg-inline'
import check from '../../assets/check.svg'


const InfoBadge = ({className, text, svg: Icon = check }) => (
  <div className={'info-container ' + (className || '') }>
    {Icon && <SVGInline className='icon' svg={Icon} />}
    <span className='badge-label'>{text}</span>
  </div>
)

export default InfoBadge
